import { create } from 'zustand';

export type EstimateStatus = 'open' | 'pending_approval' | 'approved' | 'declined';

export interface EstimatePart {
  id: string;
  name: string;
  condition: 'good' | 'fair' | 'poor';
  recommendations: string[];
  estimatedCost: {
    parts: number;
    labor: number;
  };
}

export interface Estimate {
  id: string;
  number: string;
  vehicleInfo: {
    make: string;
    model: string;
    year: string;
    vin: string;
  };
  parts: EstimatePart[];
  status: EstimateStatus;
  totalCost: number;
  createdAt: string;
  updatedAt: string;
}

interface EstimateStore {
  estimates: Estimate[];
  addEstimate: (estimate: Omit<Estimate, 'id' | 'createdAt' | 'updatedAt'>) => void;
  updateEstimateStatus: (id: string, status: EstimateStatus) => void;
  getEstimate: (id: string) => Estimate | undefined;
}

export const useEstimateStore = create<EstimateStore>((set, get) => ({
  estimates: [],
  
  addEstimate: (estimateData) => {
    const now = new Date().toISOString();
    const newEstimate: Estimate = {
      ...estimateData,
      id: crypto.randomUUID(),
      createdAt: now,
      updatedAt: now,
    };
    
    set((state) => ({
      estimates: [newEstimate, ...state.estimates],
    }));
    
    return newEstimate;
  },
  
  updateEstimateStatus: (id, status) => {
    set((state) => ({
      estimates: state.estimates.map((estimate) =>
        estimate.id === id
          ? { ...estimate, status, updatedAt: new Date().toISOString() }
          : estimate
      ),
    }));
  },
  
  getEstimate: (id) => {
    return get().estimates.find((estimate) => estimate.id === id);
  },
}));