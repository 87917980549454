import React, { useState } from 'react';
import VINScanner from './VINScanner';
import MediaCapture from './MediaCapture';
import PartAnalysis from './PartAnalysis';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/Tabs';

interface InspectionState {
  vin: string;
  vehicleInfo: {
    make: string;
    model: string;
    year: string;
  } | null;
  media: {
    id: string;
    type: 'photo' | 'video';
    preview: string;
    timestamp: number;
  }[];
}

export default function InspectionLayout() {
  const [activeTab, setActiveTab] = useState('vin');
  const [inspectionState, setInspectionState] = useState<InspectionState>({
    vin: '',
    vehicleInfo: null,
    media: []
  });

  const handleVINComplete = (vin: string, vehicleInfo: InspectionState['vehicleInfo']) => {
    setInspectionState(prev => ({ ...prev, vin, vehicleInfo }));
    setActiveTab('media');
  };

  const handleMediaComplete = (media: InspectionState['media']) => {
    setInspectionState(prev => ({ ...prev, media }));
    setActiveTab('analysis');
  };

  const isTabDisabled = (tabValue: string) => {
    switch (tabValue) {
      case 'vin':
        return false;
      case 'media':
        return !inspectionState.vehicleInfo;
      case 'analysis':
        return !inspectionState.vehicleInfo || inspectionState.media.length === 0;
      default:
        return false;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-2xl font-bold text-gray-900">New Vehicle Inspection</h1>
            {inspectionState.vehicleInfo && (
              <div className="text-sm text-gray-600">
                {inspectionState.vehicleInfo.year} {inspectionState.vehicleInfo.make} {inspectionState.vehicleInfo.model}
              </div>
            )}
          </div>
          
          <Tabs value={activeTab} className="w-full" onChange={setActiveTab}>
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="vin" disabled={false}>
                1. VIN Scanner
              </TabsTrigger>
              <TabsTrigger value="media" disabled={isTabDisabled('media')}>
                2. Media Capture {inspectionState.media.length > 0 && `(${inspectionState.media.length})`}
              </TabsTrigger>
              <TabsTrigger value="analysis" disabled={isTabDisabled('analysis')}>
                3. AI Analysis
              </TabsTrigger>
            </TabsList>
            <TabsContent value="vin">
              <VINScanner onComplete={handleVINComplete} />
            </TabsContent>
            <TabsContent value="media">
              <MediaCapture 
                media={inspectionState.media}
                onComplete={handleMediaComplete}
              />
            </TabsContent>
            <TabsContent value="analysis">
              <PartAnalysis media={inspectionState.media} />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
}