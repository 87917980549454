import React from 'react';
import { Zap, Shield, Clock, Database } from 'lucide-react';

export default function Features() {
  const features = [
    {
      icon: <Zap className="h-6 w-6" />,
      title: 'Lightning Fast',
      description: 'Process inspections and generate estimates in minutes, not hours.'
    },
    {
      icon: <Shield className="h-6 w-6" />,
      title: 'Reliable & Secure',
      description: 'Enterprise-grade security with encrypted data transmission.'
    },
    {
      icon: <Clock className="h-6 w-6" />,
      title: 'Real-time Updates',
      description: 'Instant synchronization across all devices and systems.'
    },
    {
      icon: <Database className="h-6 w-6" />,
      title: 'Smart Integration',
      description: 'Seamless connection with your existing DMS and workflows.'
    }
  ];

  return (
    <div id="features" className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Powerful Features for Modern Service Departments
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Everything you need to streamline your inspection process and boost efficiency.
          </p>
        </div>

        <div className="mt-20">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
            {features.map((feature, index) => (
              <div key={index} className="relative bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <div className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-600 text-white">
                    {feature.icon}
                  </div>
                </div>
                <div className="mt-8 text-center">
                  <h3 className="text-lg font-medium text-gray-900">{feature.title}</h3>
                  <p className="mt-2 text-gray-500">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}