import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Workflow from './components/Workflow';
import InspectionLayout from './components/inspection/InspectionLayout';
import EstimateList from './components/estimates/EstimateList';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DebugConsole from './components/DebugConsole';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <Routes>
          <Route path="/" element={
            <main>
              <Hero />
              <Features />
              <Workflow />
            </main>
          } />
          <Route path="/inspection" element={<InspectionLayout />} />
          <Route path="/estimates" element={
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 pt-24">
              <EstimateList />
            </div>
          } />
        </Routes>
        <DebugConsole />
      </div>
    </Router>
  );
}

export default App;