interface EnvConfig {
  OPENAI_API_KEY: string;
}

export const env: EnvConfig = {
  OPENAI_API_KEY: import.meta.env.VITE_OPENAI_API_KEY || ''
};

// Add this check to warn about missing environment variables
const requiredEnvVars = ['VITE_OPENAI_API_KEY'];
const missingEnvVars = requiredEnvVars.filter(key => !import.meta.env[key]);

if (missingEnvVars.length > 0) {
  console.warn('Missing required environment variables:', missingEnvVars);
}

export function getEnvVar(key: keyof EnvConfig): string {
  const value = env[key];
  if (!value) {
    console.warn(`Environment variable ${key} is not set`);
  }
  return value;
} 