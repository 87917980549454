import React from 'react';

interface TabsProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  children: React.ReactNode;
}

interface TabsListProps {
  className?: string;
  children: React.ReactNode;
}

interface TabsTriggerProps {
  value: string;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
}

interface TabsContentProps {
  value: string;
  children: React.ReactNode;
}

export function Tabs({ value, onChange, className = '', children }: TabsProps) {
  return (
    <div className={`w-full ${className}`} data-active-tab={value}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { activeTab: value, setActiveTab: onChange });
        }
        return child;
      })}
    </div>
  );
}

export function TabsList({ className = '', children }: TabsListProps) {
  return (
    <div className={`flex space-x-1 rounded-lg bg-gray-100 p-1 ${className}`}>
      {children}
    </div>
  );
}

export function TabsTrigger({ value, disabled = false, children, className = '' }: TabsTriggerProps & { activeTab?: string; setActiveTab?: (value: string) => void }) {
  const isActive = value === (arguments[0] as any).activeTab;
  
  return (
    <button
      className={`flex-1 px-3 py-2 text-sm font-medium rounded-md
        data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=active]:shadow-sm
        data-[state=inactive]:text-gray-600 data-[state=inactive]:hover:text-gray-900
        disabled:opacity-50 disabled:cursor-not-allowed
        transition-all ${className}`}
      data-state={isActive ? 'active' : 'inactive'}
      onClick={() => !disabled && (arguments[0] as any).setActiveTab?.(value)}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export function TabsContent({ value, children }: TabsContentProps & { activeTab?: string }) {
  if (value !== (arguments[0] as any).activeTab) return null;
  
  return (
    <div className="mt-4 focus:outline-none">
      {children}
    </div>
  );
}