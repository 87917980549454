import OpenAI from 'openai';
import { getEnvVar } from '../config/env';

const initializeOpenAI = () => {
  const apiKey = getEnvVar('OPENAI_API_KEY');
  if (!apiKey) {
    console.warn('OpenAI API key is missing');
    return null;
  }
  return new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true
  });
};

const openai = initializeOpenAI();

// Common automotive terms and phrases for better matching
const AUTOMOTIVE_TERMS = [
  'brake', 'brakes', 'rotor', 'rotors', 'pad', 'pads',
  'suspension', 'shock', 'shocks', 'strut', 'struts',
  'tire', 'tires', 'wheel', 'wheels', 'alignment',
  'engine', 'transmission', 'clutch', 'exhaust',
  'oil', 'filter', 'filters', 'leak', 'leaking',
  'noise', 'vibration', 'check engine', 'warning light',
  'battery', 'alternator', 'starter', 'electrical',
  'steering', 'power steering', 'rack', 'pinion',
  'belt', 'belts', 'hose', 'hoses', 'coolant',
  'air conditioning', 'ac', 'a/c', 'heat', 'heater',
  'window', 'windows', 'door', 'doors', 'lock', 'locks',
  'sensor', 'sensors', 'computer', 'module',
  'maintenance', 'service', 'repair', 'replace',
  'front', 'rear', 'left', 'right', 'driver', 'passenger'
];

export async function transcribeAudio(input: Blob | File): Promise<string> {
  if (!openai) {
    console.warn('OpenAI client not initialized - missing API key');
    return "Transcription unavailable - API key not configured";
  }

  try {
    console.log('Starting transcription process...');
    console.log('Input type:', input.type, 'size:', input.size);

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    console.log('Is iOS device:', isIOS);

    let transcriptionText = '';

    // For iOS, try to send the video directly first
    if (isIOS) {
      try {
        console.log('Attempting direct iOS transcription...');
        const videoFile = new File([input], 'video.mp4', { type: 'video/mp4' });
        console.log('Created video file for transcription:', videoFile.type, videoFile.size);

        // Try multiple times with different prompts
        const attempts = [
          { prompt: "This is an automotive inspection recording discussing car problems and repairs." },
          { prompt: "Vehicle inspection discussing maintenance issues, parts, and repairs needed." },
          { prompt: "Car mechanic explaining issues found during vehicle inspection." }
        ];

        for (const { prompt } of attempts) {
          try {
            const transcription = await openai.audio.transcriptions.create({
              file: videoFile,
              model: "whisper-1",
              language: "en",
              response_format: "text",
              temperature: 0.2,
              prompt
            });

            transcriptionText = transcription.toString().trim();
            if (transcriptionText && containsAutomotiveTerms(transcriptionText)) {
              console.log('Found valid transcription with automotive terms');
              break;
            }
          } catch (error) {
            console.warn('Attempt failed, trying next prompt...');
          }
        }

        if (transcriptionText) {
          transcriptionText = postProcessTranscription(transcriptionText);
          console.log('Direct iOS transcription successful:', transcriptionText);
          return transcriptionText;
        }
      } catch (error) {
        console.error('Direct iOS transcription failed:', error);
        console.log('Falling back to audio extraction...');
      }
    }

    // If direct transcription fails or not iOS, try audio extraction
    console.log('Using audio extraction...');
    const audioBlob = await extractAudioFromVideo(input);
    console.log('Audio extracted, size:', audioBlob.size);

    const audioFile = new File([audioBlob], 'audio.mp3', { type: 'audio/mp3' });
    console.log('Created audio file:', audioFile.type, audioFile.size);

    console.log('Sending to OpenAI Whisper API...');
    const transcription = await openai.audio.transcriptions.create({
      file: audioFile,
      model: "whisper-1",
      language: "en",
      response_format: "text",
      temperature: 0.2,
      prompt: "Vehicle inspection discussing maintenance issues, parts, and repairs needed."
    });

    transcriptionText = transcription.toString().trim();
    transcriptionText = postProcessTranscription(transcriptionText);
    console.log('Transcription received:', transcriptionText);

    if (!transcriptionText) {
      return "No speech detected in the recording";
    }

    return transcriptionText;
  } catch (error) {
    console.error('Transcription process failed:', error);
    return "Failed to transcribe audio. Please ensure there is clear speech in the recording.";
  }
}

function containsAutomotiveTerms(text: string): boolean {
  const lowerText = text.toLowerCase();
  return AUTOMOTIVE_TERMS.some(term => lowerText.includes(term.toLowerCase()));
}

function postProcessTranscription(text: string): string {
  // Convert common misheard words
  const corrections: { [key: string]: string } = {
    'break': 'brake',
    'breaks': 'brakes',
    'tire higher': 'tire wear',
    'tired': 'tire',
    'wheeled': 'wheel',
    'breaking': 'braking',
    'shock absorber': 'shock',
    'power staring': 'power steering',
    'alignment issue': 'alignment',
    'check engine light': 'check engine',
    // Add more common corrections as needed
  };

  let processed = text;

  // Apply corrections
  Object.entries(corrections).forEach(([wrong, right]) => {
    const regex = new RegExp(`\\b${wrong}\\b`, 'gi');
    processed = processed.replace(regex, right);
  });

  // Fix common formatting issues
  processed = processed
    .replace(/(\d+)\s*millimeters/gi, '$1mm')
    .replace(/(\d+)\s*inches/gi, '$1"')
    .replace(/(\d+)\s*percent/gi, '$1%')
    .replace(/([a-z])(\d)/gi, '$1 $2') // Add space between letters and numbers
    .replace(/\s+/g, ' ') // Remove extra spaces
    .trim();

  return processed;
}

async function extractAudioFromVideo(videoBlob: Blob): Promise<Blob> {
  return new Promise((resolve, reject) => {
    try {
      console.log('Starting audio extraction...');
      const video = document.createElement('video');
      video.src = URL.createObjectURL(videoBlob);
      video.playsInline = true;
      
      const audioContext = new AudioContext();
      const destination = audioContext.createMediaStreamDestination();
      const chunks: Blob[] = [];
      
      video.oncanplay = async () => {
        try {
          console.log('Video loaded, creating audio stream...');
          const source = audioContext.createMediaElementSource(video);
          source.connect(destination);

          // Try to get a supported MIME type
          let mimeType = 'audio/webm';
          if (MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
            mimeType = 'audio/webm;codecs=opus';
          }
          console.log('Using MIME type:', mimeType);

          const recorder = new MediaRecorder(destination.stream, {
            mimeType,
            audioBitsPerSecond: 128000
          });
          
          recorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              chunks.push(event.data);
              console.log('Audio chunk recorded:', event.data.size);
            }
          };

          recorder.onstop = () => {
            try {
              const audioBlob = new Blob(chunks, { type: mimeType });
              console.log('Audio extraction completed:', audioBlob.size);
              resolve(audioBlob);
            } catch (error) {
              console.error('Error creating audio blob:', error);
              reject(error);
            } finally {
              cleanup();
            }
          };

          video.currentTime = 0;
          await video.play();
          recorder.start(100);

          // Record for a fixed duration
          setTimeout(() => {
            recorder.stop();
            video.pause();
          }, 5000); // Record for 5 seconds max

        } catch (error) {
          console.error('Error in audio processing:', error);
          reject(error);
          cleanup();
        }
      };

      video.onerror = () => {
        console.error('Video loading error:', video.error);
        reject(new Error('Failed to load video'));
        cleanup();
      };

      function cleanup() {
        URL.revokeObjectURL(video.src);
        video.remove();
        audioContext.close();
      }
    } catch (error) {
      console.error('Error in extraction setup:', error);
      reject(error);
    }
  });
}