import { createWorker } from 'tesseract.js';
import * as tf from '@tensorflow/tfjs';
import * as cocoSsd from '@tensorflow-models/coco-ssd';

let worker: Tesseract.Worker | null = null;
let objectDetectionModel: cocoSsd.ObjectDetection | null = null;
let isInitializing = false;

export async function initializeOCR() {
  if (worker || isInitializing) return;

  try {
    isInitializing = true;
    
    // Initialize TensorFlow.js
    await tf.ready();
    
    // Create Tesseract worker with minimal configuration
    worker = await createWorker();
    await worker.loadLanguage('eng');
    await worker.initialize('eng');
    await worker.setParameters({
      tessedit_char_whitelist: '0123456789ABCDEFGHJKLMNPRSTUVWXYZ',
      tessedit_pageseg_mode: '1'
    });

    // Load object detection model
    objectDetectionModel = await cocoSsd.load({
      base: 'lite_mobilenet_v2'
    });
    
    isInitializing = false;
    console.log('OCR initialization completed successfully');
  } catch (error) {
    console.error('Error initializing OCR:', error);
    isInitializing = false;
    worker = null;
    objectDetectionModel = null;
    throw new Error('Failed to initialize OCR services');
  }
}

export async function detectVINFromImage(videoElement: HTMLVideoElement): Promise<string | null> {
  try {
    if (!worker || !objectDetectionModel) {
      console.log('Initializing OCR services...');
      await initializeOCR();
    }

    if (!worker || !objectDetectionModel) {
      throw new Error('OCR services not initialized');
    }

    // Create a canvas and get its context
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const ctx = canvas.getContext('2d');
    
    if (!ctx) {
      throw new Error('Could not get canvas context');
    }

    // Draw the current video frame
    ctx.drawImage(videoElement, 0, 0);

    // Detect if there's a car in the frame
    const predictions = await objectDetectionModel.detect(canvas);
    const carDetected = predictions.some(p => p.class === 'car' && p.score > 0.5);

    if (!carDetected) {
      return null;
    }

    // Process image for better OCR results
    const processedCanvas = await preprocessImageForVIN(canvas);
    
    try {
      // Perform OCR
      const result = await worker.recognize(processedCanvas);
      const text = result.data.text;
      const possibleVIN = cleanVINString(text);
      
      if (possibleVIN && validateVIN(possibleVIN)) {
        return possibleVIN;
      }
    } catch (ocrError) {
      console.error('OCR processing error:', ocrError);
    }

    return null;
  } catch (error) {
    console.error('Error during VIN detection:', error);
    return null;
  }
}

async function preprocessImageForVIN(canvas: HTMLCanvasElement): Promise<HTMLCanvasElement> {
  const processedCanvas = document.createElement('canvas');
  processedCanvas.width = canvas.width;
  processedCanvas.height = canvas.height;
  const ctx = processedCanvas.getContext('2d');
  
  if (!ctx) {
    throw new Error('Could not get canvas context');
  }

  // Draw original image
  ctx.drawImage(canvas, 0, 0);
  
  // Get image data
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  // Apply preprocessing
  for (let i = 0; i < data.length; i += 4) {
    const r = data[i];
    const g = data[i + 1];
    const b = data[i + 2];
    
    // Convert to grayscale
    const gray = 0.299 * r + 0.587 * g + 0.114 * b;
    
    // Apply contrast enhancement
    const contrast = 1.5;
    const factor = (259 * (contrast + 255)) / (255 * (259 - contrast));
    const color = factor * (gray - 128) + 128;
    
    // Threshold to binary
    const final = color > 128 ? 255 : 0;

    // Set RGB channels to the same value for binary image
    data[i] = final;
    data[i + 1] = final;
    data[i + 2] = final;
  }

  // Put processed image data back
  ctx.putImageData(imageData, 0, 0);
  return processedCanvas;
}

function cleanVINString(text: string): string | null {
  // Remove any non-VIN characters
  const cleaned = text.replace(/[^A-HJ-NPR-Z0-9]/gi, '');
  
  // Look for a 17-character sequence that matches VIN pattern
  const match = cleaned.match(/[A-HJ-NPR-Z0-9]{17}/i);
  return match ? match[0].toUpperCase() : null;
}

function validateVIN(vin: string): boolean {
  if (vin.length !== 17) return false;

  const validChars = /^[A-HJ-NPR-Z0-9]{17}$/;
  if (!validChars.test(vin)) return false;

  const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
  const values: { [key: string]: number } = {
    'A': 1, 'B': 2, 'C': 3, 'D': 4, 'E': 5, 'F': 6, 'G': 7, 'H': 8,
    'J': 1, 'K': 2, 'L': 3, 'M': 4, 'N': 5, 'P': 7, 'R': 9,
    'S': 2, 'T': 3, 'U': 4, 'V': 5, 'W': 6, 'X': 7, 'Y': 8, 'Z': 9,
    '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8,
    '9': 9, '0': 0
  };

  let sum = 0;
  for (let i = 0; i < 17; i++) {
    sum += values[vin[i]] * weights[i];
  }

  const checkDigit = vin[8];
  const expectedCheckDigit = (sum % 11).toString();
  const expectedCheckDigitX = expectedCheckDigit === '10' ? 'X' : expectedCheckDigit;

  return checkDigit === expectedCheckDigitX;
}