import React, { useEffect, useRef } from 'react';
import { useDebugStore } from '../store/debugStore';
import { X, Trash2, ChevronUp, ChevronDown } from 'lucide-react';

export default function DebugConsole() {
  const { logs, clearLogs } = useDebugStore();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(true);
  const consoleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (consoleRef.current && isExpanded) {
      consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
    }
  }, [logs, isExpanded]);

  const getLogColor = (type: string) => {
    switch (type) {
      case 'error':
        return 'text-red-600';
      case 'warn':
        return 'text-yellow-600';
      case 'success':
        return 'text-green-600';
      default:
        return 'text-gray-600';
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg">
      <div className="flex items-center justify-between px-4 py-2 border-b border-gray-200">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="p-1 hover:bg-gray-100 rounded"
          >
            {isExpanded ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
          </button>
          <h3 className="text-sm font-medium text-gray-700">Debug Console</h3>
          <span className="text-xs text-gray-500">({logs.length} logs)</span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={clearLogs}
            className="p-1 hover:bg-gray-100 rounded text-gray-600"
            title="Clear logs"
          >
            <Trash2 size={16} />
          </button>
          <button
            onClick={() => setIsVisible(false)}
            className="p-1 hover:bg-gray-100 rounded text-gray-600"
            title="Close console"
          >
            <X size={16} />
          </button>
        </div>
      </div>
      
      {isExpanded && (
        <div 
          ref={consoleRef}
          className="h-64 overflow-y-auto p-4 space-y-2 font-mono text-sm bg-gray-50"
        >
          {logs.map((log) => (
            <div key={log.id} className="flex items-start space-x-2">
              <span className="text-gray-400 text-xs">
                {new Date(log.timestamp).toLocaleTimeString()}
              </span>
              <span className={getLogColor(log.type)}>
                {log.message}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
} 