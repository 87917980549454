import React from 'react';
import { Eye } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

export default function Navbar() {
  const location = useLocation();

  return (
    <nav className="bg-white shadow-lg fixed w-full top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <Eye className="h-8 w-8 text-blue-600" />
              <span className="ml-2 text-xl font-bold text-gray-900">Fixed EYE</span>
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            {location.pathname === '/' ? (
              <>
                <a href="#features" className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium">Features</a>
                <a href="#workflow" className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium">Workflow</a>
              </>
            ) : (
              <Link to="/estimates" className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium">
                Estimates
              </Link>
            )}
            <Link
              to="/inspection"
              className="bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-700 transition-colors"
            >
              New Inspection
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}