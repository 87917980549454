import React from 'react';
import { Camera, Cog, FileSpreadsheet } from 'lucide-react';

export default function Hero() {
  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-50 pt-24 pb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block">Streamline Your</span>
            <span className="block text-blue-600">Vehicle Inspections</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            AI-powered inspection system that revolutionizes how automotive service departments handle vehicle assessments and estimates.
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="rounded-md shadow">
              <button className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10">
                Start Free Trial
              </button>
            </div>
          </div>
        </div>

        <div className="mt-20 grid grid-cols-1 gap-8 md:grid-cols-3">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white mx-auto">
              <Camera className="h-6 w-6" />
            </div>
            <h3 className="mt-4 text-center text-lg font-medium text-gray-900">Smart Capture</h3>
            <p className="mt-2 text-center text-gray-500">
              AI-powered photo and video capture with automatic part recognition.
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white mx-auto">
              <Cog className="h-6 w-6" />
            </div>
            <h3 className="mt-4 text-center text-lg font-medium text-gray-900">Automated Processing</h3>
            <p className="mt-2 text-center text-gray-500">
              Instant VIN decoding and integration with your existing systems.
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white mx-auto">
              <FileSpreadsheet className="h-6 w-6" />
            </div>
            <h3 className="mt-4 text-center text-lg font-medium text-gray-900">Real-time Estimates</h3>
            <p className="mt-2 text-center text-gray-500">
              Generate accurate estimates with real-time parts and labor data.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}