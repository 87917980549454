import OpenAI from 'openai';

console.log('OpenAI API Key exists:', !!import.meta.env.VITE_OPENAI_API_KEY);

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY || 'dummy_key',
  dangerouslyAllowBrowser: true
});

export async function analyzeTranscription(transcription: string) {
  if (!import.meta.env.VITE_OPENAI_API_KEY) {
    console.warn('OpenAI API key is missing');
    return generateFallbackAnalysis();
  }

  try {
    console.log('Analyzing transcription:', transcription);

    // If transcription is empty or indicates failure, return fallback
    if (!transcription || transcription.includes('Failed to transcribe') || transcription.includes('unavailable')) {
      return generateFallbackAnalysis();
    }

    // Save transcription for debugging
    try {
      localStorage.setItem('lastTranscription', transcription);
    } catch (error) {
      console.warn('Failed to save transcription to localStorage:', error);
    }

    const response = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: `You are an expert automotive technician analyzing a vehicle inspection recording. Your task is to identify specific issues, repairs, and maintenance needs from the audio transcription.

          Important Instructions:
          1. Listen carefully for any mentioned problems, symptoms, or concerns
          2. Identify specific parts that need attention
          3. Assess the severity and urgency of each issue
          4. Estimate parts costs based on current market prices
          5. Calculate labor hours needed for repairs
          6. Provide detailed descriptions of the issues

          For EACH identified issue, provide:
          - Name: Clear title of the issue
          - Severity: "critical" for safety/major issues, "moderate" for maintenance, "minor" for cosmetic/optional
          - Urgency: "immediate" for safety/critical, "soon" for needed maintenance, "future" for monitoring
          - Description: Detailed explanation of the problem
          - Parts: List with quantities and estimated costs
          - Labor: Hours needed and description of work

          Format your response as valid JSON like this:
          {
            "recommendations": [
              {
                "name": "Specific Issue Name",
                "severity": "critical|moderate|minor",
                "urgency": "immediate|soon|future",
                "description": "Detailed description",
                "parts": [
                  {
                    "name": "Specific Part Name",
                    "quantity": 1,
                    "cost": 100.00
                  }
                ],
                "labor": {
                  "hours": 1.5,
                  "rate": 125,
                  "description": "Specific repair steps"
                }
              }
            ]
          }

          Always provide specific recommendations based on the audio content. Do not return generic responses.
          If you can't identify specific issues, explain what information is missing or unclear.`
        },
        {
          role: "user",
          content: transcription
        }
      ],
      temperature: 0.3,
      max_tokens: 2000,
      presence_penalty: -0.5,
      frequency_penalty: 0.3
    });

    const content = response.choices[0]?.message?.content;
    if (!content) {
      console.warn('No content in OpenAI response');
      return generateFallbackAnalysis();
    }

    console.log('Raw GPT response:', content);

    // Save response for debugging
    try {
      localStorage.setItem('lastGPTResponse', content);
    } catch (error) {
      console.warn('Failed to save GPT response to localStorage:', error);
    }

    try {
      // Try to extract JSON from the response
      const jsonMatch = content.match(/\{[\s\S]*\}/);
      if (jsonMatch) {
        const jsonStr = jsonMatch[0];
        console.log('Extracted JSON:', jsonStr);
        const parsedResponse = JSON.parse(jsonStr);
        
        if (!parsedResponse.recommendations || !Array.isArray(parsedResponse.recommendations)) {
          console.warn('Invalid response structure:', parsedResponse);
          return convertTextToRecommendations(content);
        }

        // Validate and normalize each recommendation
        parsedResponse.recommendations = parsedResponse.recommendations.map(rec => ({
          name: rec.name || 'Unspecified Issue',
          severity: validateSeverity(rec.severity),
          urgency: validateUrgency(rec.urgency),
          description: rec.description || 'Issue requires attention',
          parts: validateParts(rec.parts),
          labor: validateLabor(rec.labor)
        }));

        // Save parsed recommendations for debugging
        try {
          localStorage.setItem('lastParsedRecommendations', JSON.stringify(parsedResponse));
        } catch (error) {
          console.warn('Failed to save parsed recommendations to localStorage:', error);
        }

        return parsedResponse;
      } else {
        console.warn('No JSON found in response:', content);
        return convertTextToRecommendations(content);
      }
    } catch (parseError) {
      console.error('Error parsing OpenAI response:', parseError);
      return convertTextToRecommendations(content);
    }
  } catch (error) {
    console.error('Error analyzing transcription:', error);
    return generateFallbackAnalysis();
  }
}

function validateSeverity(severity: string): 'critical' | 'moderate' | 'minor' {
  const valid = ['critical', 'moderate', 'minor'];
  return valid.includes(severity) ? severity as any : 'moderate';
}

function validateUrgency(urgency: string): 'immediate' | 'soon' | 'future' {
  const valid = ['immediate', 'soon', 'future'];
  return valid.includes(urgency) ? urgency as any : 'soon';
}

function validateParts(parts: any[]): { name: string; quantity: number; cost: number; }[] {
  if (!Array.isArray(parts) || parts.length === 0) {
    return [{
      name: 'Generic Parts',
      quantity: 1,
      cost: 100
    }];
  }
  return parts.map(part => ({
    name: part.name || 'Unspecified Part',
    quantity: Number(part.quantity) || 1,
    cost: Number(part.cost) || 100
  }));
}

function validateLabor(labor: any): { hours: number; rate: number; description: string; } {
  return {
    hours: Number(labor?.hours) || 1,
    rate: Number(labor?.rate) || 125,
    description: labor?.description || 'Standard labor'
  };
}

function convertTextToRecommendations(text: string) {
  return {
    recommendations: [{
      name: "Inspection Analysis",
      severity: "moderate",
      urgency: "soon",
      description: text,
      parts: [{
        name: "Required Parts",
        quantity: 1,
        cost: 150
      }],
      labor: {
        hours: 1.5,
        rate: 125,
        description: "Inspection and repairs"
      }
    }]
  };
}

function generateFallbackAnalysis() {
  return {
    recommendations: [
      {
        name: "General Inspection",
        severity: "moderate",
        urgency: "soon",
        description: "Based on the inspection audio, maintenance may be required. Please consult with a technician for specific details.",
        parts: [
          {
            name: "Standard Parts Kit",
            quantity: 1,
            cost: 150
          }
        ],
        labor: {
          hours: 1,
          rate: 125,
          description: "General inspection and maintenance"
        }
      }
    ]
  };
}