import React, { useState, useRef } from 'react';
import { Video, Upload, Square, Loader2 } from 'lucide-react';
import { transcribeAudio } from '../../services/videoService';

interface MediaCaptureProps {
  media: Array<{
    id: string;
    type: 'photo' | 'video';
    preview: string;
    timestamp: number;
    transcription?: string;
  }>;
  onComplete: (media: MediaCaptureProps['media']) => void;
}

export default function MediaCapture({ media: initialMedia, onComplete }: MediaCaptureProps) {
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const startRecording = async () => {
    try {
      setError(null);
      chunksRef.current = [];
      setIsProcessing(false);

      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      setError(`Initializing camera (iOS: ${isIOS})`); // Debug info

      const constraints = {
        video: isIOS ? {
          facingMode: 'environment'
        } : {
          facingMode: 'environment',
          width: { ideal: 1280, max: 1920 },
          height: { ideal: 720, max: 1080 }
        },
        audio: true
      };

      setError('Requesting camera access...');
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setError(null);

      streamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.setAttribute('playsinline', '');
        videoRef.current.setAttribute('autoplay', '');
        videoRef.current.setAttribute('muted', '');
        
        try {
          await videoRef.current.play();
        } catch (playError) {
          setError(`Video preview error: ${playError.message}`);
          throw playError;
        }
      }

      // For iOS, use simpler recording settings
      const mimeType = isIOS ? 'video/mp4' : (
        MediaRecorder.isTypeSupported('video/webm;codecs=h264') 
          ? 'video/webm;codecs=h264'
          : 'video/webm'
      );

      setError(`Setting up recorder with type: ${mimeType}`);
      const recorder = new MediaRecorder(stream, {
        mimeType,
        videoBitsPerSecond: isIOS ? 1000000 : 2500000
      });

      recorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          chunksRef.current.push(event.data);
          setError(`Recorded chunk: ${event.data.size} bytes`); // Debug info
        }
      };

      recorder.onerror = (event) => {
        setError(`Recording error: ${event.error.message}`);
      };

      recorder.onstop = async () => {
        try {
          setError('Processing recording...');
          const videoBlob = new Blob(chunksRef.current, { type: mimeType });
          setError(`Created video blob: ${videoBlob.size} bytes`);
          
          if (videoBlob.size === 0) {
            throw new Error('No video data recorded');
          }

          if (videoBlob.size < 1000) {
            throw new Error('Video data too small, recording may have failed');
          }

          const videoUrl = URL.createObjectURL(videoBlob);
          setError('Created video URL, starting transcription...');

          let transcription = "Processing transcription...";
          try {
            setError('Calling transcribeAudio...');
            transcription = await transcribeAudio(videoBlob);
            setError('Transcription completed successfully');
            console.log('Transcription result:', transcription);
          } catch (transcriptionError: any) {
            const errorMessage = transcriptionError?.message || 'Unknown transcription error';
            setError(`Transcription error: ${errorMessage}`);
            console.error('Detailed transcription error:', transcriptionError);
            transcription = "Failed to transcribe audio";
          }

          setError('Creating media object...');
          const newMedia = {
            id: crypto.randomUUID(),
            type: 'video' as const,
            preview: videoUrl,
            timestamp: Date.now(),
            transcription
          };

          setError('Updating media list...');
          onComplete([...initialMedia, newMedia]);
          setError(null);
        } catch (error: any) {
          const errorMessage = error?.message || 'Unknown error';
          setError(`Processing error: ${errorMessage}`);
          console.error('Detailed processing error:', error);
        } finally {
          setIsProcessing(false);
          cleanup();
        }
      };

      mediaRecorderRef.current = recorder;
      recorder.start(1000);
      setIsRecording(true);
      setError(null);
    } catch (error) {
      setError(`Camera error: ${error.message}`);
      cleanup();
    }
  };

  const stopRecording = () => {
    setError('Stopping recording...');
    if (mediaRecorderRef.current && isRecording) {
      setIsProcessing(true);
      try {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
      } catch (error) {
        setError(`Stop error: ${error.message}`);
        cleanup();
      }
    }
  };

  const cleanup = () => {
    setError('Starting cleanup...');
    if (streamRef.current) {
      setError('Stopping media tracks...');
      streamRef.current.getTracks().forEach(track => {
        track.stop();
        setError(`Stopped track: ${track.kind}`);
      });
    }
    if (videoRef.current) {
      setError('Clearing video source...');
      videoRef.current.srcObject = null;
    }
    streamRef.current = null;
    mediaRecorderRef.current = null;
    chunksRef.current = [];
    setError('Cleanup completed');
    setTimeout(() => setError(null), 2000); // Clear error after 2 seconds
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('video/')) {
      setError('Please select a video file');
      return;
    }

    try {
      setIsProcessing(true);
      setError(null);

      const videoUrl = URL.createObjectURL(file);
      
      // Get transcription for uploaded video
      let transcription = "Processing transcription...";
      try {
        transcription = await transcribeAudio(file); // Pass the file directly
        console.log('Upload transcription:', transcription);
      } catch (transcriptionError) {
        console.error('Upload transcription error:', transcriptionError);
        transcription = "Failed to transcribe audio";
      }

      const newMedia = {
        id: crypto.randomUUID(),
        type: 'video' as const,
        preview: videoUrl,
        timestamp: Date.now(),
        transcription
      };

      onComplete([...initialMedia, newMedia]);
    } catch (error) {
      console.error('Error processing video:', error);
      setError('Failed to process video');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Record or Upload Video</h3>
        
        <div className="space-y-4">
          <div className={`relative aspect-video bg-black rounded-lg overflow-hidden ${!isRecording && 'hidden'}`}>
            <video
              ref={videoRef}
              className="absolute inset-0 w-full h-full object-cover"
              playsInline
              autoPlay
              muted
            />
            {isRecording && (
              <div className="absolute top-4 right-4 flex items-center space-x-2 bg-black bg-opacity-50 px-3 py-1 rounded-full">
                <div className="w-3 h-3 rounded-full bg-red-500 animate-pulse" />
                <span className="text-white text-sm">Recording</span>
              </div>
            )}
          </div>

          <div className="flex flex-col sm:flex-row gap-4">
            {!isRecording ? (
              <>
                <button
                  onClick={startRecording}
                  disabled={isProcessing}
                  className="flex-1 flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <Video className="w-5 h-5 mr-2" />
                  Start Recording
                </button>

                <div className="flex-1">
                  <input
                    type="file"
                    accept="video/*"
                    capture="environment"
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                    className="hidden"
                  />
                  <button
                    onClick={() => fileInputRef.current?.click()}
                    disabled={isProcessing}
                    className="w-full flex items-center justify-center px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <Upload className="w-5 h-5 mr-2" />
                    Upload Video
                  </button>
                </div>
              </>
            ) : (
              <button
                onClick={stopRecording}
                className="flex-1 flex items-center justify-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                <Square className="w-5 h-5 mr-2" />
                Stop Recording
              </button>
            )}
          </div>

          {error && (
            <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
              {error}
            </div>
          )}

          {isProcessing && (
            <div className="flex items-center justify-center space-x-2 text-gray-600">
              <Loader2 className="w-5 h-5 animate-spin" />
              <span>Processing video...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}