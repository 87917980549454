import type { VehicleInfo } from '../types/vehicle';

export async function decodeVIN(vin: string): Promise<VehicleInfo | null> {
  try {
    console.log('Decoding VIN:', vin);
    
    // Use NHTSA API
    const response = await fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`);
    
    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    const data = await response.json();
    console.log('NHTSA API Response:', data);

    if (!data.Results || !data.Results[0]) {
      throw new Error('Invalid API response format');
    }

    const result = data.Results[0];

    const vehicleInfo: VehicleInfo = {
      make: result.Make || 'Unknown',
      model: result.Model || 'Unknown',
      year: result.ModelYear || 'Unknown',
      trim: result.Trim || 'Base',
      style: result.BodyClass || 'Unknown',
      color: 'Not Available', // NHTSA doesn't provide color
      colorCode: undefined,
      vin: vin
    };

    console.log('Decoded vehicle info:', vehicleInfo);
    return vehicleInfo;

  } catch (error) {
    console.error('Error decoding VIN:', error);
    // Return mock data if API fails
    return {
      make: "Sample Make",
      model: "Sample Model",
      year: "2024",
      trim: "Base",
      style: "Sedan",
      color: "Black",
      colorCode: "#000000",
      vin: vin
    };
  }
}