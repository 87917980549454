import React, { useState, useRef, useEffect } from 'react';
import { Camera, Search, Loader2, X, Clipboard } from 'lucide-react';
import { detectVINFromImage, initializeOCR } from '../../services/ocrService';
import { decodeVIN } from '../../services/vinDecoder';
import type { VehicleInfo } from '../../types/vehicle';

interface VINScannerProps {
  onComplete: (vin: string, vehicleInfo: VehicleInfo) => void;
}

export default function VINScanner({ onComplete }: VINScannerProps) {
  const [vin, setVin] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const processingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const init = async () => {
      try {
        await initializeOCR();
        setIsInitialized(true);
      } catch (error) {
        console.error('Failed to initialize OCR:', error);
        setError('Failed to initialize camera scanning. Manual input is still available.');
      }
    };
    init();
    return () => {
      stopScanning();
      if (processingTimeoutRef.current) {
        clearTimeout(processingTimeoutRef.current);
      }
    };
  }, []);

  const handleManualInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    setVin(value);
    setError(null);
  };

  const processVIN = async (value: string) => {
    if (!value || value.length !== 17) {
      setError('Please enter a valid 17-character VIN');
      return;
    }

    setIsLoading(true);
    setError(null);
    
    try {
      console.log('Processing VIN:', value);
      const info = await decodeVIN(value);
      
      if (info) {
        console.log('VIN decoded successfully:', info);
        setVehicleInfo(info);
        setError(null);
      } else {
        setError('Unable to decode VIN. Please check the VIN and try again.');
        setVehicleInfo(null);
      }
    } catch (err) {
      console.error('VIN decoding error:', err);
      setError('Error decoding VIN. Please try again or enter vehicle information manually.');
      setVehicleInfo(null);
    } finally {
      setIsLoading(false);
    }
  };

  const startScanning = async () => {
    if (!isInitialized) {
      setError('Camera scanning is not available. Please use manual input.');
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { 
          facingMode: 'environment',
          width: { ideal: 1280 },
          height: { ideal: 720 }
        }
      });
      
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
        setIsScanning(true);
        setError(null);
        processVideoFrame();
      }
    } catch (err) {
      console.error('Error accessing camera:', err);
      setError('Unable to access camera. Please ensure camera permissions are granted.');
      setIsScanning(false);
    }
  };

  const processVideoFrame = async () => {
    if (!videoRef.current || !isScanning || isProcessing) return;

    setIsProcessing(true);
    try {
      const detectedVIN = await detectVINFromImage(videoRef.current);
      
      if (detectedVIN) {
        setVin(detectedVIN);
        await processVIN(detectedVIN);
        stopScanning();
        return;
      }
    } catch (error) {
      console.error('Error processing video frame:', error);
    } finally {
      setIsProcessing(false);
    }

    if (isScanning) {
      processingTimeoutRef.current = setTimeout(processVideoFrame, 500);
    }
  };

  const stopScanning = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    if (processingTimeoutRef.current) {
      clearTimeout(processingTimeoutRef.current);
    }
    setIsScanning(false);
    setIsProcessing(false);
  };

  const handleContinue = () => {
    if (vehicleInfo) {
      onComplete(vin, vehicleInfo);
      stopScanning();
    }
  };

  const handleDecodeClick = () => {
    if (vin.length === 17) {
      processVIN(vin);
    } else {
      setError('Please enter a valid 17-character VIN');
    }
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Enter VIN Manually</h3>
            <div className="space-y-4">
              <div className="relative">
                <input
                  type="text"
                  value={vin}
                  onChange={handleManualInput}
                  placeholder="Enter VIN (17 characters)"
                  className={`w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                    error ? 'border-red-300' : 'border-gray-300'
                  }`}
                  maxLength={17}
                  disabled={isLoading}
                />
                {isLoading && (
                  <div className="absolute right-3 top-2.5">
                    <Loader2 className="h-5 w-5 text-gray-400 animate-spin" />
                  </div>
                )}
              </div>

              <button
                onClick={handleDecodeClick}
                disabled={isLoading || vin.length !== 17}
                className="w-full flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <Clipboard className="mr-2 h-5 w-5" />
                Decode VIN
              </button>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Scan VIN with Camera</h3>
            <button
              onClick={startScanning}
              disabled={!isInitialized && !isScanning}
              className={`w-full flex items-center justify-center px-4 py-2 rounded-md ${
                isScanning
                  ? 'bg-red-600 hover:bg-red-700'
                  : 'bg-blue-600 hover:bg-blue-700'
              } text-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              {isScanning ? (
                <>
                  <X className="mr-2 h-5 w-5" />
                  Stop Scanning
                </>
              ) : (
                <>
                  <Camera className="mr-2 h-5 w-5" />
                  Start Camera Scan
                </>
              )}
            </button>

            {isScanning && (
              <div className="mt-4 relative aspect-video bg-black rounded-lg overflow-hidden">
                <video
                  ref={videoRef}
                  className="absolute inset-0 w-full h-full object-cover"
                  playsInline
                  muted
                />
                <div className="absolute inset-0 border-2 border-blue-500 opacity-50" />
                {isProcessing && (
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <Loader2 className="h-8 w-8 text-white animate-spin" />
                  </div>
                )}
              </div>
            )}
          </div>

          {error && (
            <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
              {error}
            </div>
          )}
        </div>

        <div className="space-y-4">
          {vehicleInfo && (
            <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Vehicle Information</h3>
              <dl className="grid grid-cols-2 gap-4">
                <div>
                  <dt className="text-sm font-medium text-gray-500">Year</dt>
                  <dd className="text-sm text-gray-900">{vehicleInfo.year}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Make</dt>
                  <dd className="text-sm text-gray-900">{vehicleInfo.make}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Model</dt>
                  <dd className="text-sm text-gray-900">{vehicleInfo.model}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Trim</dt>
                  <dd className="text-sm text-gray-900">{vehicleInfo.trim}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Style</dt>
                  <dd className="text-sm text-gray-900">{vehicleInfo.style}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Color</dt>
                  <dd className="text-sm text-gray-900 flex items-center">
                    {vehicleInfo.color}
                    {vehicleInfo.colorCode && (
                      <span
                        className="ml-2 w-4 h-4 rounded-full border border-gray-200"
                        style={{ backgroundColor: vehicleInfo.colorCode }}
                      />
                    )}
                  </dd>
                </div>
              </dl>
              <button
                onClick={handleContinue}
                className="mt-6 w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
              >
                Continue
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}