import { create } from 'zustand';

interface DebugLog {
  id: string;
  timestamp: number;
  message: string;
  type: 'info' | 'error' | 'warn' | 'success';
}

interface DebugStore {
  logs: DebugLog[];
  addLog: (message: string, type?: DebugLog['type']) => void;
  clearLogs: () => void;
}

export const useDebugStore = create<DebugStore>((set) => ({
  logs: [],
  addLog: (message, type = 'info') => set((state) => ({
    logs: [...state.logs, {
      id: crypto.randomUUID(),
      timestamp: Date.now(),
      message,
      type
    }]
  })),
  clearLogs: () => set({ logs: [] })
}));

// Override console methods to capture logs
const originalConsole = {
  log: console.log,
  error: console.error,
  warn: console.warn,
  info: console.info
};

console.log = (...args) => {
  useDebugStore.getState().addLog(args.map(arg => 
    typeof arg === 'object' ? JSON.stringify(arg, null, 2) : String(arg)
  ).join(' '), 'info');
  originalConsole.log(...args);
};

console.error = (...args) => {
  useDebugStore.getState().addLog(args.map(arg => 
    typeof arg === 'object' ? JSON.stringify(arg, null, 2) : String(arg)
  ).join(' '), 'error');
  originalConsole.error(...args);
};

console.warn = (...args) => {
  useDebugStore.getState().addLog(args.map(arg => 
    typeof arg === 'object' ? JSON.stringify(arg, null, 2) : String(arg)
  ).join(' '), 'warn');
  originalConsole.warn(...args);
}; 