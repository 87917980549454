import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEstimateStore } from '../../store/estimateStore';
import { AlertTriangle, AlertCircle, Info, Clock, DollarSign, Plus, Check } from 'lucide-react';
import { analyzeTranscription } from '../../services/aiAnalysisService';

interface PartAnalysisProps {
  media: Array<{
    id: string;
    type: 'photo' | 'video';
    preview: string;
    timestamp: number;
    transcription?: string;
  }>;
}

interface Recommendation {
  name: string;
  severity: 'critical' | 'moderate' | 'minor';
  urgency: 'immediate' | 'soon' | 'future';
  description: string;
  parts: Array<{
    name: string;
    quantity: number;
    cost: number;
  }>;
  labor: {
    hours: number;
    rate: number;
    description: string;
  };
}

export default function PartAnalysis({ media }: PartAnalysisProps) {
  const navigate = useNavigate();
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());
  const [analyzedMediaIds, setAnalyzedMediaIds] = useState<Set<string>>(new Set());
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const addEstimate = useEstimateStore((state) => state.addEstimate);

  const getSeverityIcon = (severity: Recommendation['severity']) => {
    switch (severity) {
      case 'critical':
        return <AlertTriangle className="h-5 w-5 text-red-500" />;
      case 'moderate':
        return <AlertCircle className="h-5 w-5 text-yellow-500" />;
      case 'minor':
        return <Info className="h-5 w-5 text-blue-500" />;
    }
  };

  const getUrgencyIcon = (urgency: Recommendation['urgency']) => {
    switch (urgency) {
      case 'immediate':
        return <Clock className="h-5 w-5 text-red-500" />;
      case 'soon':
        return <Clock className="h-5 w-5 text-yellow-500" />;
      case 'future':
        return <Clock className="h-5 w-5 text-blue-500" />;
    }
  };

  const getSeverityClass = (severity: Recommendation['severity'], urgency: Recommendation['urgency']) => {
    if (urgency === 'immediate') {
      return 'bg-red-50 border-red-200';
    }
    if (urgency === 'soon') {
      return 'bg-yellow-50 border-yellow-200';
    }
    if (urgency === 'future') {
      return 'bg-blue-50 border-blue-200';
    }

    switch (severity) {
      case 'critical':
        return 'bg-red-50 border-red-200';
      case 'moderate':
        return 'bg-yellow-50 border-yellow-200';
      case 'minor':
        return 'bg-blue-50 border-blue-200';
      default:
        return 'bg-gray-50 border-gray-200';
    }
  };

  const getUrgencyLabel = (urgency: Recommendation['urgency']) => {
    switch (urgency) {
      case 'immediate':
        return 'Needs immediate attention';
      case 'soon':
        return 'Service needed soon';
      case 'future':
        return 'Keep an eye on it';
    }
  };

  const getUrgencyTextColor = (urgency: Recommendation['urgency']) => {
    switch (urgency) {
      case 'immediate':
        return 'text-red-600';
      case 'soon':
        return 'text-yellow-600';
      case 'future':
        return 'text-blue-600';
      default:
        return 'text-gray-600';
    }
  };

  const handleAnalyzeMedia = async (mediaItem: PartAnalysisProps['media'][0]) => {
    if (analyzedMediaIds.has(mediaItem.id) || !mediaItem.transcription) return;

    setIsAnalyzing(true);
    try {
      const analysis = await analyzeTranscription(mediaItem.transcription);
      
      // Add unique identifier to each recommendation
      const newRecommendations = analysis.recommendations.map(rec => ({
        ...rec,
        id: `${mediaItem.id}-${rec.name}-${Date.now()}`
      }));

      setRecommendations(prev => {
        // Create a Set of existing recommendation names to avoid duplicates
        const existingNames = new Set(prev.map(r => r.name));
        // Only add recommendations that don't already exist
        const uniqueNewRecs = newRecommendations.filter(rec => !existingNames.has(rec.name));
        return [...prev, ...uniqueNewRecs];
      });

      setAnalyzedMediaIds(prev => new Set([...prev, mediaItem.id]));
    } catch (error) {
      console.error('Error analyzing media:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };

  const toggleItemSelection = (index: number) => {
    const newSelected = new Set(selectedItems);
    if (newSelected.has(index)) {
      newSelected.delete(index);
    } else {
      newSelected.add(index);
    }
    setSelectedItems(newSelected);
  };

  const getTotalCost = () => {
    return Array.from(selectedItems).reduce((total, index) => {
      const recommendation = recommendations[index];
      const partsCost = recommendation.parts.reduce((sum, part) => sum + (part.cost * part.quantity), 0);
      const laborCost = recommendation.labor.hours * recommendation.labor.rate;
      return total + partsCost + laborCost;
    }, 0);
  };

  const handleSaveEstimate = () => {
    if (selectedItems.size === 0) return;

    const selectedRecommendations = Array.from(selectedItems).map(index => recommendations[index]);
    const estimate = {
      id: crypto.randomUUID(),
      number: `EST-${Date.now().toString().slice(-6)}`,
      vehicleInfo: {
        make: "Sample Make",
        model: "Sample Model",
        year: "2024",
        vin: "1HGCM82633A123456"
      },
      parts: selectedRecommendations.map(rec => ({
        id: crypto.randomUUID(),
        name: rec.name,
        condition: rec.severity,
        recommendations: [rec.description],
        estimatedCost: {
          parts: rec.parts.reduce((sum, part) => sum + (part.cost * part.quantity), 0),
          labor: rec.labor.hours * rec.labor.rate
        }
      })),
      status: 'open' as const,
      totalCost: getTotalCost(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    addEstimate(estimate);
    navigate('/estimates');
  };

  useEffect(() => {
    // Only analyze media items that haven't been analyzed yet
    media.forEach(item => {
      if (item.transcription && !analyzedMediaIds.has(item.id)) {
        handleAnalyzeMedia(item);
      }
    });
  }, [media]);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Media Analysis</h3>
            <div className="space-y-4">
              {media.map((item) => (
                <div key={item.id} className="relative">
                  {item.type === 'video' ? (
                    <div className="aspect-video">
                      <video
                        src={item.preview}
                        className="w-full rounded-lg"
                        controls
                      />
                    </div>
                  ) : (
                    <img
                      src={item.preview}
                      alt="Captured media"
                      className="w-full h-32 object-cover rounded-lg"
                    />
                  )}
                  {analyzedMediaIds.has(item.id) && (
                    <div className="absolute bottom-2 right-2 bg-green-600 text-white text-xs px-2 py-1 rounded-full">
                      Analyzed
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Recommended Services</h3>
            {recommendations.length === 0 ? (
              <div className="text-center text-gray-500 py-8">
                {isAnalyzing ? (
                  <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mb-2"></div>
                    Analyzing inspection data...
                  </div>
                ) : (
                  'No recommendations yet. Analyze media to identify issues.'
                )}
              </div>
            ) : (
              <div className="space-y-4">
                {recommendations.map((rec, index) => (
                  <div
                    key={rec.id}
                    className={`p-4 rounded-lg border ${getSeverityClass(rec.severity, rec.urgency)} relative`}
                  >
                    <div className="absolute top-4 right-4">
                      <button
                        onClick={() => toggleItemSelection(index)}
                        className={`p-2 rounded-full transition-colors ${
                          selectedItems.has(index)
                            ? 'bg-green-600 text-white'
                            : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
                        }`}
                      >
                        {selectedItems.has(index) ? (
                          <Check className="h-4 w-4" />
                        ) : (
                          <Plus className="h-4 w-4" />
                        )}
                      </button>
                    </div>

                    <div className="flex items-start space-x-3 mb-3">
                      {getUrgencyIcon(rec.urgency)}
                      <div>
                        <h4 className="font-medium text-gray-900">{rec.name}</h4>
                        <div className="flex items-center mt-1 space-x-2">
                          <span className={`text-sm ${getUrgencyTextColor(rec.urgency)}`}>
                            {getUrgencyLabel(rec.urgency)}
                          </span>
                        </div>
                      </div>
                    </div>

                    <p className="text-sm text-gray-600 mb-3">{rec.description}</p>

                    <div className="space-y-3">
                      <div>
                        <h5 className="text-sm font-medium text-gray-900">Parts Required</h5>
                        <ul className="mt-1 space-y-1">
                          {rec.parts.map((part, partIndex) => (
                            <li key={partIndex} className="text-sm text-gray-600 flex justify-between">
                              <span>{part.quantity}x {part.name}</span>
                              <span>${(part.cost * part.quantity).toFixed(2)}</span>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div>
                        <h5 className="text-sm font-medium text-gray-900">Labor Estimate</h5>
                        <div className="text-sm text-gray-600 flex justify-between">
                          <span>{rec.labor.hours} hours @ ${rec.labor.rate}/hr</span>
                          <span>${(rec.labor.hours * rec.labor.rate).toFixed(2)}</span>
                        </div>
                        <p className="text-sm text-gray-500 mt-1">{rec.labor.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex justify-between items-center">
            <div className="text-sm text-gray-600">
              {selectedItems.size} items selected
            </div>
            <button
              onClick={handleSaveEstimate}
              disabled={selectedItems.size === 0}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Save Estimate
            </button>
          </div>
        </div>
      </div>

      {selectedItems.size > 0 && (
        <div className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-4 border border-blue-100">
          <div className="flex items-center space-x-2">
            <DollarSign className="h-5 w-5 text-blue-600" />
            <div>
              <div className="text-sm text-gray-600">Total Estimate</div>
              <div className="text-xl font-bold text-blue-600">
                ${getTotalCost().toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}