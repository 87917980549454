import React from 'react';
import { Camera, QrCode, Brain, FileText, CheckCircle, ArrowRight } from 'lucide-react';

export default function Workflow() {
  const steps = [
    {
      icon: <QrCode className="h-6 w-6" />,
      title: 'VIN Scan',
      description: 'Instant vehicle identification through VIN scanning'
    },
    {
      icon: <Camera className="h-6 w-6" />,
      title: 'Media Capture',
      description: 'High-quality photo and video documentation'
    },
    {
      icon: <Brain className="h-6 w-6" />,
      title: 'AI Analysis',
      description: 'Automatic part recognition and condition assessment'
    },
    {
      icon: <FileText className="h-6 w-6" />,
      title: 'Estimate Generation',
      description: 'Real-time pricing and labor time calculation'
    },
    {
      icon: <CheckCircle className="h-6 w-6" />,
      title: 'Customer Approval',
      description: 'Digital approval process with visual evidence'
    }
  ];

  return (
    <div id="workflow" className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Streamlined Inspection Process
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Our intelligent workflow automates every step of the inspection process
          </p>
        </div>

        <div className="mt-16">
          <div className="relative">
            {/* Connection Line */}
            <div className="absolute top-1/2 left-0 w-full h-0.5 bg-blue-200 -translate-y-1/2 hidden md:block" />
            
            <div className="relative grid grid-cols-1 gap-8 md:grid-cols-5">
              {steps.map((step, index) => (
                <div key={index} className="relative flex flex-col items-center">
                  {/* Step Number */}
                  <div className="absolute -top-4 bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center text-sm font-bold">
                    {index + 1}
                  </div>
                  
                  {/* Icon Container */}
                  <div className="bg-white w-20 h-20 rounded-full shadow-lg flex items-center justify-center z-10">
                    <div className="text-blue-600">
                      {step.icon}
                    </div>
                  </div>

                  {/* Arrow for mobile */}
                  {index < steps.length - 1 && (
                    <div className="flex justify-center my-4 md:hidden">
                      <ArrowRight className="h-6 w-6 text-blue-400" />
                    </div>
                  )}

                  {/* Content */}
                  <div className="mt-8 text-center">
                    <h3 className="text-lg font-medium text-gray-900">{step.title}</h3>
                    <p className="mt-2 text-sm text-gray-500">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-16 text-center">
          <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
            See It In Action
          </button>
        </div>
      </div>
    </div>
  );
}